h1,
h2,
h3,
h4,
h5,
h6 {
  --nc-bg-2: rgba(0, 0, 0, 0);
}

img {
  margin: 0;
}

header a {
  text-decoration: none;
}

.uppload-close {
  display: none;
}

.uppload-modal section {
  margin-bottom: 0;
}

.block {
  border: 1px dashed var(--nc-bg-3);
  padding: 2rem;
  margin: 0 0 1rem 5%;
  display: table;
}

.block:last-of-type {
  margin-bottom: 5rem;
}

.block:empty {
  border: 0;
}

.block--borderless {
  border: 0;
  padding: 1rem;
  margin: 0 -1rem 1rem;
  width: 100%;
}

.block img {
  width: 500px;
  max-width: 100%;
  cursor: pointer;
}

.block--borderless img {
  width: auto;
}

.block--borderless input {
  margin: 0 auto;
  display: block;
}

.block--owner {
  background: #f5faff;
}

input[name='caption'] {
  width: 100%;
  font-size: 200%;
}

.hide,
.hide:hover,
.hide:active {
  background: none;
  color: var(--nc-lk-1);
  text-decoration: underline;
}

.actions {
  display: flex;
  flex-direction: row-reverse;
}
.react-images__blanket {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

.react-images__view-image {
  max-width: 90vw !important;
  max-height: 90vh !important;
}

.home crate {
  display: none;
}
